<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card card-body printableArea" v-else>
      <h3>
        <b>Detail</b>
        <!-- <span class="pull-right">
           <div class="btn-group">
             <button @click="onDownload" class="btn btn-sm btn-success" title="Print">
              <i class="mdi mdi-printer"></i>Print Lembar Disposisi
            </button>
           </div>
         </span> -->
        <span class="pull-right">
          <button
            @click="onDownloadLembarDispo"
            :loading="printDispoLoading"
            :disabled="printDispoLoading"
            class="btn btn-sm btn-success"
            title="Print"
          >
            <i
              v-if="printDispoLoading"
              class="fa fa-spinner fa-pulse fa-fw mr-1"
            ></i>
            <span
              v-if="!printDispoLoading"
              class="badge badge-pill badge-light mr-1"
              >PDF</span
            >
            Print Lembar Disposisi
          </button>
        </span>
      </h3>
      <hr />

      <div class="row">
        <div class="col-md-12 justify-content-md-center">
          <gtTabs :tabIndex="2">
            <gtTab title="Detail Disposisi">
              <div class="col-md-12"></div>
              <div class="col-md-12 pull-left">
                <small class="text-muted">Agenda Number</small>
                <h6>
                  {{
                    disposisi.agenda_number === ""
                      ? "-"
                      : disposisi.agenda_number
                  }}
                </h6>
                <small class="text-muted">Penerima</small>
                <h6>
                  <ul>
                    <li v-for="item in disposisi.receiver" v-bind:key="item.id">
                      <span
                        data-toggle="tooltip"
                        title="informasi"
                        v-if="item.status == 1"
                      >
                        <i class="fa fa-check"></i>
                        <i class="fa fa-none"></i>
                      </span>
                      <span
                        data-toggle="tooltip"
                        title="pelaksana"
                        v-else-if="item.status == 2"
                      >
                        <i class="fa fa-check"></i>
                        <i class="fa fa-check"></i>
                      </span>
                      {{ item.text }}
                    </li>
                  </ul>
                </h6>
                <small class="text-muted">Personal</small>
                <h6>
                  <ul>
                    <li v-for="item in personal" v-bind:key="item.id">
                      {{ item.name }}
                      <b>({{ item.unit }})</b>
                    </li>
                  </ul>
                </h6>
                <small class="text-muted">Instruksi</small>
                <h6>
                  <ul>
                    <li
                      v-for="(item, key) in disposisi.commands"
                      v-bind:key="item.id"
                    >
                      <b>{{ key }}</b
                      >&nbsp;<i
                        class="fa fa-search"
                        style="
                          cursor: pointer;
                          background: green;
                          padding: 4px;
                          color: #fff;
                          border-radius: 5px;
                        "
                        @click="showModalInstruction(item)"
                      ></i>
                    </li>
                  </ul>
                </h6>
                <small class="text-muted">Catatan</small>
                <h6 v-for="(note, index) in disposisi.note" :key="index">
                  <b>{{ index }} : </b> <span v-html="note"></span>
                </h6>
                <!-- <small class="text-muted">Jawaban</small> -->
                <!-- <h6 v-if="disposisi.notes === ''">-</h6>
                <h6 v-else>
                  <ul>
                    <li v-for="(item,key) in disposisi.notes" v-bind:key="item">
                      <b>{{ key }}</b>&nbsp;<i class="fa fa-search" style="cursor:pointer;background:blue;padding:4px;color:#fff;border-radius:5px;" @click="showModalNote(item)"></i>                      
                    </li>
                  </ul>  
                </h6> -->
                <ModalDefault
                  v-show="isVisible"
                  :title="modalTitle"
                  :content="modalContent"
                  :action="ActionChangeable"
                  :confirmation="isConfirmation"
                  :information="isInformation"
                  @close="onClose"
                />
                <!-- <small class="text-muted">Catatan</small>
                <h6>
                  <ul>
                    <li v-for="(item,key) in disposisi.notes" v-bind:key="item">
                      <b>{{key}}</b> :
                      <ul>
                        <li v-for="i in item" v-bind:key="i">
                          <p style="color:green" v-if="i.is_receiver == 1">
                            <b>{{i.receiver}}</b> :
                            <span v-html="i.note"></span>
                          </p>
                          <p v-else>
                            <b>{{i.receiver}}</b> :
                            <span v-html="i.note"></span>
                          </p>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </h6> -->
                <small class="text-muted">Status Disposisi</small>
                <h6>
                  <span v-if="disposisi.is_cancel == 1">Dibatalkan</span>
                  <span v-else-if="disposisi.is_cancel == 0">Aktif</span>
                  <span v-else>-</span>
                </h6>
                <small v-if="disposisi.is_false == 1" class="text-muted"
                  >Konfirmasi</small
                >
                <h6 v-if="disposisi.is_false == 1" style="color: red">
                  Salah kirim
                </h6>
              </div>
            </gtTab>

            <gtTab title="Detail Surat">
              <div class="col-md-6 pull-left">
                <small class="text-muted">Tanggal Surat</small>
                <h6>
                  {{
                    surat === undefined ? "-" : formatDate(surat.tanggal_surat)
                  }}
                </h6>
                <small class="text-muted">Dari</small>
                <h6>
                  {{ surat === undefined ? "-" : surat.sender.unit.name }}
                </h6>
                <small class="text-muted">Perihal</small>
                <h6>{{ surat === undefined ? "-" : surat.subject }}</h6>
                <small class="text-muted">Agenda</small>
                <h6>{{ surat === undefined ? "-" : surat.agenda_number }}</h6>
                <small class="text-muted">Jenis Dokumen / Nama Dokumen</small>
                <h6>
                  {{ surat === undefined ? "-" : surat.document_jenis.text }}
                </h6>
                <small class="text-muted">Sifat Surat</small>
                <h6>{{ surat === undefined ? "-" : surat.sifat }}</h6>
                <small class="text-muted">Kecepatan Surat</small>
                <h6>
                  {{ surat === undefined ? "-" : surat.document_kecrat.text }}
                </h6>
              </div>

              <div class="col-md-6 pull-right">
                <small class="text-muted">Ringkasan Surat</small>
                <h6><span v-html="surat.description"></span></h6>
                <small class="text-muted">Nomor Dokumen</small>
                <h6>{{ surat === undefined ? "-" : surat.nomor_surat }}</h6>
                <small class="text-muted">Halaman / Lampiran</small>
                <h6>
                  {{ surat.pages === "" ? "-" : surat.pages }}{{ " / " }}
                  {{ surat.jumlah === "" ? "-" : surat.jumlah }}
                  {{
                    surat.document_lampiran === ""
                      ? "-"
                      : surat.document_lampiran.text
                  }}
                </h6>
                <!-- <h6>{{ surat === undefined ? '-' : surat.attachment_total }}</h6>  -->
                <small class="text-muted">Kepada</small>
                <h6>
                  <span v-if="surat === undefined"></span>
                  <ol v-else>
                    <li v-for="item in surat.receiver" v-bind:key="item.id">
                      {{ item.name }}
                    </li>
                  </ol>
                </h6>
                <small class="text-muted">Tembusan</small>
                <h6 v-if="surat.receiver2 === ''">-</h6>
                <h6 v-else>
                  <table>
                    <tr
                      v-for="(rec, index) in surat.receiver2"
                      :key="rec.value"
                    >
                      <td>{{ index + 1 + ". " + rec.text }}</td>
                      <td style="margin-left: 10px">
                        <i
                          v-if="rec.approval == 2"
                          class="fa fa-envelope"
                          style="
                            cursor: pointer;
                            background: blue;
                            padding: 4px;
                            color: #fff;
                            border-radius: 5px;
                          "
                          @click="showModal(rec)"
                        ></i>
                        <i
                          v-else-if="rec.approval == 1"
                          class="fa fa-envelope"
                          style="
                            cursor: pointer;
                            background: green;
                            padding: 4px;
                            color: #fff;
                            border-radius: 5px;
                          "
                          @click="showModal(rec)"
                        ></i>
                        <i
                          v-else-if="rec.approval == 3"
                          class="fa fa-envelope"
                          style="
                            cursor: pointer;
                            background: red;
                            padding: 4px;
                            color: #fff;
                            border-radius: 5px;
                          "
                          @click="showModal(rec)"
                        ></i>
                      </td>
                    </tr>
                  </table>

                  <!-- <table>
                      
                      <tr v-for="(rec,index) in state.detail.carbon" :key="rec.value">
                        <td>{{ (index+1) + ". " + rec.text }}</td>
                        <td style="margin-left:10px;">
                          <i v-if="rec.approval==2" class="fa fa-envelope" style="cursor:pointer;background:blue;padding:4px;color:#fff;border-radius:5px;" @click="showModal(rec)"></i>
                          <i v-else-if="rec.approval==1" class="fa fa-envelope" style="cursor:pointer;background:green;padding:4px;color:#fff;border-radius:5px;" @click="showModal(rec)"></i>
                          <i v-else-if="rec.approval==3" class="fa fa-envelope" style="cursor:pointer;background:red;padding:4px;color:#fff;border-radius:5px;" @click="showModal(rec)"></i>
                        </td>
                      </tr>
                      
                  </table>-->
                </h6>
              </div>
            </gtTab>

            <gtTab title="File Terlampir">
              <div class="col-md-12">
                <h4>Surat</h4>
                <table class="table table-striped">
                  <tr>
                    <th width="35%">File</th>
                    <th width="25%">Pengirim</th>
                    <th width="25%">Tanggal</th>
                    <th width="15%" class="text-center">Buka File</th>
                  </tr>
                  <span v-if="surat === undefined"></span>
                  <tr v-else-if="!surat.files.length" class="text-center">
                    <td colspan="4">
                      <i>File tidak ada</i>
                    </td>
                  </tr>
                  <tr
                    v-for="file in surat.files"
                    v-bind:key="file.filename"
                    style="margin: 5px"
                  >
                    <td>{{ file.filename }}</td>
                    <td>
                      {{
                        disposisi.surat.sender.name === undefined
                          ? "-"
                          : disposisi.surat.sender.name
                      }}
                    </td>
                    <td>{{ formatDate(disposisi.surat.tanggal_surat) }}</td>
                    <td class="text-center">
                      <!-- <a
                        class="btn btn-success text-white"
                        @click="downloadForce(file.url)"
                        title="Download File"
                        ><i class="fa fa-download text-white"></i></a
                      >&nbsp; -->
                      <a
                        class="btn btn-info text-white"
                        @click="prevDoc(file.uuid)"
                        title="View File"
                        ><i class="fa fa-eye text-white"></i
                      ></a>

                      <!-- <a
                    class="btn btn-success text-white"
                    @click="downloadForce(file.url)"
                    title="Download File"
                    ><i class="fa fa-download text-white"></i>
                  </a>
                  &nbsp;
                  <a
                    v-if="file.filename.includes('.pdf')"
                    @click="prev(file.uuid)"
                    class="btn btn-info text-white"
                    title="View File"
                    ><i class="fa fa-eye text-white"></i>
                  </a> -->
                    </td>
                  </tr>
                </table>
                <hr />
                <h4>Disposisi</h4>
                <table class="table table-striped">
                  <tr>
                    <th width="35%">Nama File</th>
                    <th width="25%">Pengirim</th>
                    <th width="25%">Tanggal Diunggah</th>
                    <th width="15%" class="text-center">Aksi</th>
                  </tr>
                  <tr v-if="!disposisi.files.length" class="text-center">
                    <td colspan="4">
                      <i>File tidak ada</i>
                    </td>
                  </tr>
                  <tr
                    v-for="file in disposisi.files"
                    v-bind:key="file.filename"
                    style="margin: 5px"
                  >
                    <td>{{ file.filename }}</td>
                    <td>{{ file.sender }}</td>
                    <td>{{ formatDate(disposisi.created_at) }}</td>
                    <td class="text-center">
                      <a
                        class="btn btn-success text-white"
                        @click="downloadForce(file.url)"
                        title="Download File"
                        ><i class="fa fa-download text-white"></i></a
                      >&nbsp;
                      <a
                        v-if="file.filename.includes('.pdf')"
                        class="btn btn-info text-white"
                        @click="prev(file.uuid)"
                        title="View File"
                        ><i class="fa fa-eye text-white"></i
                      ></a>

                      <!-- <a
                    class="btn btn-success text-white"
                    @click="downloadForce(file.url)"
                    title="Download File"
                    ><i class="fa fa-download text-white"></i>
                  </a>
                  &nbsp;
                  <a
                    v-if="file.filename.includes('.pdf')"
                    @click="prev(file.uuid)"
                    class="btn btn-info text-white"
                    title="View File"
                    ><i class="fa fa-eye text-white"></i>
                  </a> -->
                    </td>
                  </tr>
                </table>
              </div>
            </gtTab>

            <gtTab title="Tracking">
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <TreeView class="item" :model="treeData" />
                    </div>
                  </div>
                </div>
              </div>
            </gtTab>

            <gtTab title="History">
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <h5>
                        <ol>
                          <li
                            v-if="state.detail.history.disposition != undefined"
                          >
                            <span
                              v-html="state.detail.history.disposition"
                            ></span>
                          </li>
                        </ol>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </gtTab>
          </gtTabs>
        </div>
        <!-- <h3>{{ response_dispo }}</h3> -->
      </div>
      <hr />
      <span class="pull-right" v-if="response_dispo === ''">
        <button
          type="button"
          @click="goJawabDispo()"
          class="btn btn-info"
          title="Jawab Disposisi"
          v-if="disposisi.is_cancel == 0"
        >
          Jawab Dispo</button
        >&nbsp;
        <button
          type="button"
          @click="goBack()"
          class="btn btn-inverse"
          title="Kembali"
        >
          Kembali
        </button>
      </span>
      <span class="pull-right" v-else>
        <!-- <button type="button" @click="goTeruskan()" class="btn btn-info" v-if="response_dispo.response_dispo == '1' && response_dispo.is_false === 0 && canDispo">Teruskan Disposisi</button>&nbsp;&nbsp; -->
        <button
          type="button"
          @click="goTeruskan()"
          class="btn btn-info"
          v-if="
            ((response_dispo == '1' &&
              disposisi.jenis_dispo == 'Personal & Struktural') ||
              (response_dispo == '1' &&
                disposisi.jenis_dispo == 'Struktural')) &&
            disposisi.is_cancel == 0
          "
          title="Teruskan Disposisi"
        >
          Teruskan Disposisi</button
        >&nbsp;&nbsp;
        <button
          type="button"
          @click="goBack()"
          class="btn btn-inverse"
          title="Kembali"
        >
          Kembali
        </button>
      </span>
    </div>
  </div>
</template>
<script>
import { RotateSquare5 } from "vue-loading-spinner";
import axios from "axios";
import { gtTab, gtTabs } from "@/components/gtTabs";
import TreeView from "@/components/tree-view/index";
import Header from "@/components/pageTitle/index";
var pdfMake = require("pdfmake/build/pdfmake");
var pdfFonts = require("pdfmake/build/vfs_fonts");
pdfMake.vfs = pdfFonts.pdfMake.vfs;
export default {
  components: {
    RotateSquare5,
    gtTab,
    gtTabs,
    TreeView,
    Header,
  },
  data() {
    return {
      isVisible: false,
      modalTitle: "",
      modalContent: "",
      isModalVisible: false,
      isConfirmation: false,
      isInformation: false,
      isConfirmation: false,
      isInformation: false,
      ActionChangeable: null,
      printDispoLoading: false,
      headers: {
        nama_file: {
          label: "Nama File",
          sortable: false,
        },
        nama_pengunggah: {
          label: "Nama Pengunggah",
          sortable: false,
        },
        tanggal_upload: {
          label: "Tanggal Upload",
          sortable: false,
        },
      },
    };
  },
  computed: {
    canDispo() {
      if (this.$store.state.profile.permissions.disposition_in) {
        return this.$store.state.profile.permissions.disposition_in.create;
      }
      return false;
    },
    treeData() {
      return this.$store.state.dispositionIn.detail.tracking !== undefined
        ? this.$store.state.dispositionIn.detail.tracking
        : {};
    },
    base() {
      return this.baseUrl;
    },
    state() {
      console.log(this.$store.state.dispositionIn);
      return this.$store.state.dispositionIn;
    },
    surat() {
      return this.state.detail.surat;
    },
    intruksi() {
      return this.state.detail.intruksi;
    },
    disposisi() {
      return this.state.detail;
    },
    personal() {
      return this.state.detail.personal;
    },
    agenda_number() {
      return this.state.detail.surat.agenda_number;
    },
    pengirim() {
      return this.state.detail.sender.name;
    },
    keterangan() {
      return this.state.detail.keterangan;
    },
    catatan() {
      return this.state.detail.note;
    },
    response_dispo() {
      return this.state.detail.response_dispo;
    },
    tujuan() {
      return this.state.detail.tracking.name;
    },
    nomorsurat() {
      return this.state.detail.nomorsurat;
    },
    perihal() {
      return this.state.detail.subject;
    },
    filename() {
      return this.state.detail.filename;
    },
    tanggalditerima() {
      return this.formatDate(this.state.detail.tanggal_diterima);
    },
    tanggaldokumen() {
      let text = "-";
      if (this.state.detail.surat != undefined) {
        text = this.formatDate(this.state.detail.surat.tanggal_surat);
      }
      return text;
    },
    tanggaldokumenditerima() {
      let text = "-";
      if (this.state.detail.surat != undefined) {
        if (this.state.detail.surat.tanggal_surat == "") {
          text = "";
        } else {
          text = this.formatDate(this.state.detail.surat.tanggal_surat);
        }
        return text;
      }
    },
    tanggaldokumenselesai() {
      let text = "-";
      if (this.state.detail.surat != undefined) {
        if (this.state.detail.surat.tanggal_surat == "") {
          text = "";
        } else {
          text = this.formatDate(this.state.detail.surat.tanggal_surat);
        }
        return text;
      }
    },
    submitted() {
      return this.formatDate(this.state.detail.created_at);
    },
    modified() {
      return this.formatDate(this.state.detail.updated_at);
    },

    urlLembarDispo() {
      return (
        this.baseUrl +
        "/disposition_in/" +
        this.$route.params.id +
        "/disposition_sheet"
      );
    },
  },
  mounted() {
    this.detailLoad();
  },
  methods: {
    showModalNote(option) {
      this.isVisible = true;
      this.modalTitle = "Catatan";
      this.modalContent = "<table class='table table-bordered'>";
      this.modalContent +=
        "<thead><tr><td><b>Penerima</b></td><td><b>Catatan</b></td></tr></thead>";
      this.modalContent += "<tbody>";
      for (let index = 0; index < option.length; index++) {
        if (option[index].is_receiver == 1) {
          this.modalContent +=
            "<tr style='background:aliceblue'><td>" +
            option[index].receiver +
            "</td><td>" +
            option[index].note +
            "</td></tr>";
        } else {
          this.modalContent +=
            "<tr><td>" +
            option[index].receiver +
            "</td><td>" +
            option[index].note +
            "</td></tr>";
        }
      }
      this.modalContent += "</tbody>";
      this.modalContent += "</table>";
    },
    showModalInstruction(option) {
      this.isVisible = true;
      this.modalTitle = "Instruksi";
      this.modalContent = "<table class='table table-bordered'>";
      this.modalContent += "<tbody>";
      for (let index = 0; index < option.length; index++) {
        if (option[index].is_receiver == 1) {
          this.modalContent +=
            "<tr style='background:aliceblue'><td>" +
            option[index].name +
            "</td></tr>";
        } else {
          this.modalContent += "<tr><td>" + option[index].name + "</td></tr>";
        }
      }
      this.modalContent += "</tbody>";
      this.modalContent += "</table>";
    },
    onClose() {
      this.isVisible = false;
    },
    detailLoad() {
      const state = {
        loaded: false,
      };
      this.$store.commit("dispositionIn/STATE", state);
      this.$store.dispatch(
        "dispositionIn/getDispositionInById",
        this.$route.params
      );
    },
    onDownload() {
      let dispoReceiver = [[]];
      dispoReceiver = this.state.detail.penerima.length
        ? this.state.detail.penerima.map((element) => {
            return [
              {
                text: element.text,
              },
              {
                text: element.status == 1 ? "v" : "v v",
              },
            ];
          })
        : [[]];

      let dispoInstruction = [[]];
      dispoInstruction = this.state.detail.perintah.length
        ? this.state.detail.perintah.map((element) => {
            return [
              {
                text: element.text,
              },
              {
                text: "",
              },
            ];
          })
        : [[]];

      var no = 0;
      let DMPenerima = [[]];
      let DMInstruksiMenlu = [[]];
      let firstDPenerima = [];
      let twiceDPenerima = [];
      let thirdDPenerima = [];

      let kiriDMPenerima = [];
      let tengahDMPenerima = [];
      let kananDMPenerima = [];
      let DMInstructionMenluKiri = [];

      let kiriDataInstructionMenlu = [];
      let kiriDataPenerima = [
        {
          style: "header12i",
          no: "",
          text: "",
        },
        { style: "header12i1", no: "1", text: "WAKIL MENTERI" },
        { style: "header12i1", no: "2", text: "SEKJEN" },
        { style: "header12i", no: "", text: "Ka. BDSP" },
        { style: "header12i", no: "", text: "Ka. BHAKP" },
        { style: "header12i", no: "", text: "Ka. BPO" },
        { style: "header12i", no: "", text: "Ka. Biro SDM" },
        { style: "header12i", no: "", text: "Ka. Biro Keuangan" },
        { style: "header12i", no: "", text: "Ka. Biro Umum" },
        { style: "header12i", no: "", text: "Ka. Pusdiklat" },
        { style: "header12i", no: "", text: "Kapus TIK KP" },
        { style: "header12i", no: "", text: "Kapus Binjabfung" },
        { style: "header12i", no: "", text: "" },
        { style: "header12i1", no: "3", text: "IRJEN" },
        { style: "header12i", no: "", text: "Ses. Itjen" },
        { style: "header12i", no: "", text: "Irwil I" },
        { style: "header12i", no: "", text: "Irwil II" },
        { style: "header12i", no: "", text: "Irwil III" },
        { style: "header12i", no: "", text: "Irwil IV" },
        { style: "header12i", no: "", text: "" },
        { style: "header12i1", no: "4", text: "DIRJEN ASPASAF" },
        { style: "header12i", no: "", text: "Ses. Ditjen ASPASAF" },
        { style: "header12i", no: "", text: "Dir. Asia Tenggara" },
        { style: "header12i", no: "", text: "Dir. Astimpas" },
        { style: "header12i", no: "", text: "Dir. Asselteng" },
        { style: "header12i", no: "", text: "Dir. Timteng" },
        { style: "header12i", no: "", text: "Dir. Afrika" },
        { style: "header12i", no: "", text: "Dir. KSI Aspasaf" },
      ];
      let tengahDataPenerima = [
        { style: "header12i", no: "", text: "" },
        { style: "header12i1", no: "5", text: "Dirjen AMEROP" },
        { style: "header12i", no: "", text: "Ses. Ditjen Amerop" },
        { style: "header12i", no: "", text: "Dir. Amerika I" },
        { style: "header12i", no: "", text: "Dir. Amerika II" },
        { style: "header12i", no: "", text: "Dir. Eropa I" },
        { style: "header12i", no: "", text: "Dir. Eropa II" },
        { style: "header12i", no: "", text: "Dir. Eropa III" },
        { style: "header12i", no: "", text: "Dir. KSI Amerop" },
        { style: "header12i", no: "", text: "" },
        { style: "header12i1", no: "6", text: "Dirjen KS ASEAN" },
        { style: "header12i", no: "", text: "Ses. Ditjen KS ASEAN" },
        { style: "header12i", no: "", text: "Dir. KS Polkam ASEAN" },
        { style: "header12i", no: "", text: "Dir. KS Ekonomi ASEAN" },
        { style: "header12i", no: "", text: "Dir. KS Sosbud ASEAN" },
        { style: "header12i", no: "", text: "Dir. KS Eksternal ASEAN" },
        { style: "header12i", no: "", text: "" },
        { style: "header12i1", no: "7", text: "Dirjen Kerjasam MULTILATERAL" },
        { style: "header12i", no: "", text: "Ses. Ditjen KS Multilateral" },
        { style: "header12i", no: "", text: "Dir. KIPS" },
        { style: "header12i", no: "", text: "Dir. HAM & Kem." },
        { style: "header12i", no: "", text: "Dir. PKKI" },
        { style: "header12i", no: "", text: "Dir. Sosbud OINB" },
        { style: "header12i", no: "", text: "" },
        { style: "header12i1", no: "8", text: "Dirjen HPI" },
        { style: "header12i", no: "", text: "Ses. Ditjen HPI" },
        { style: "header12i", no: "", text: "Dir. HP Kewilayahan" },
        { style: "header12i", no: "", text: "Dir. HP Polkam" },
        { style: "header12i", no: "", text: "Dir. HP Ekonomi" },
        { style: "header12i", no: "", text: "Dir. HP Sosbud" },
      ];

      let kananDataPenerima = [
        // {style: 'header12i',no : '', 'text' : 'Dir. HP Polkam'},
        { style: "header12i", no: "", text: "" },
        { style: "header12i1", no: "9", text: "Dirjen IDP" },
        { style: "header12i", no: "", text: "Ses. Dirjen IDP" },
        { style: "header12i", no: "", text: "Dir. Infomed" },
        { style: "header12i", no: "", text: "Dir. Diplik" },
        { style: "header12i", no: "", text: "Dir. Kamdip" },
        { style: "header12i", no: "", text: "Dir. KST" },
        { style: "header12i", no: "", text: "Ka. Museum KAA" },
        { style: "header12i", no: "", text: "" },
        { style: "header12i1", no: "10", text: "Dirjen PROTKONS" },
        { style: "header12i", no: "", text: "Ses. Ditjen Protkons" },
        { style: "header12i", no: "", text: "Dir. Protokol" },
        { style: "header12i", no: "", text: "Dir. Konsuler" },
        { style: "header12i", no: "", text: "Dir.Fasdip" },
        { style: "header12i", no: "", text: "Dir.PWNI BHI" },
        { style: "header12i", no: "", text: "" },
        { style: "header12i1", no: "11", text: "Ka. BPPK" },
        { style: "header12i", no: "", text: "Ses. BPPK" },
        { style: "header12i", no: "", text: "Kapus PPK Aspasaf" },
        { style: "header12i", no: "", text: "Kapus PPK Amerop" },

        { style: "header12i", no: "", text: "Kapus PPK Multilateral" },
        { style: "header12i", no: "", text: "" },
        { style: "header12i1", no: "12", text: "Sahli Polhukam" },
        { style: "header12i1", no: "13", text: "Sahli Dipl. Ekonomi" },
        { style: "header12i1", no: "14", text: "Sahli Dipl. Sosbud & PMILN" },
        { style: "header12i1", no: "15", text: "Sahli Hublem" },
        { style: "header12i1", no: "16", text: "Sahli Manajemen" },
        {
          style: "header12i1",
          no: "17",
          text: "Staf Khusus Isu-isu Strategis",
        },
        {
          style: "header12i1",
          no: "18",
          text: "Staf Khusus Isu-isu Prioritas",
        },
      ];
      this.state.detail.receiver.map(function (element, index) {
        if ((index + 1) % 2 == 1) {
          kiriDMPenerima.push(element);
        }

        if ((index + 1) % 2 == 1) {
          tengahDMPenerima.push(element);
        }

        if ((index + 1) % 2 == 0) {
          kananDMPenerima.push(element);
        }
      });
      const DMlength = this.state.detail.receiver.length;
      this.state.detail.receiver.map(function (element, index) {
        if ((index + 3) % 3 == 0) {
          firstDPenerima.push(element);
        }
        if ((index + 3) % 3 == 1) {
          twiceDPenerima.push(element);
        }
        if ((index + 3) % 3 == 2) {
          thirdDPenerima.push(element);
        }
      });

      for (var i = 0; i < kiriDataPenerima.length; i++) {
        if (i == 0) {
          DMPenerima[[i]] = [
            { text: "No", style: "header12i" },
            { text: "Kepada", style: "header12i" },
            { text: "Asli", style: "header12i" },
            { text: "Copy", style: "header12i" },
            { text: "No", style: "header12i" },
            { text: "Kepada", style: "header12i" },
            { text: "Asli", style: "header12i" },
            { text: "Copy", style: "header12i" },
            { text: "No", style: "header12i" },
            { text: "Kepada", style: "header12i" },
            { text: "Asli", style: "header12i" },
            { text: "Copy", style: "header12i" },
          ];
        } else {
          DMPenerima[[i]] = [
            { text: kiriDataPenerima[i].no, style: "header12i" },
            {
              text: kiriDataPenerima[i] ? kiriDataPenerima[i].text : "",
              style: "header12i",
            },
            { text: kiriDataPenerima[i] ? "" : "", style: "header12i" },
            { text: kiriDataPenerima[i] ? "" : "", style: "header12i" },
            { text: tengahDataPenerima[i].no, style: "header12i" },
            {
              text: tengahDataPenerima[i] ? tengahDataPenerima[i].text : "",
              style: "header12i",
            },
            { text: tengahDataPenerima[i] ? "" : "", style: "header12i" },
            { text: tengahDataPenerima[i] ? "" : "", style: "header12i" },
            { text: kananDataPenerima[i].no, style: "header12i" },
            {
              text: kananDataPenerima[i] ? kananDataPenerima[i].text : "",
              style: "header12i",
            },
            { text: kananDataPenerima[i] ? "" : "", style: "header12i" },
            { text: kananDataPenerima[i] ? "" : "", style: "header12i" },
          ];
        }
      }

      // End Disposisi Kepada
      let leftInstruction = [];
      let rightInstruction = [];
      this.state.detail.perintah.map(function (element, index) {
        if ((index + 1) % 2 == 1) {
          leftInstruction.push(element.text);
        } else {
          rightInstruction.push(element.text);
        }
      });

      let Instruction = [[]];
      for (var i = 0; i < leftInstruction.length; i++) {
        Instruction[[i]] = [
          {
            text: leftInstruction[i] ? leftInstruction[i] : "",
            style: "TableInstruksi",
          },
          { text: "" },
          {
            text: rightInstruction[i] ? rightInstruction[i] : "",
            style: "TableInstruksi",
          },
          { text: "" },
        ];
      }

      DMInstruksiMenlu = [
        // {'text': leftDPenerima[i] ? leftDPenerima[i] : '', 'style': "TableInstruksi"},
        { text: "" },
        {
          text: "Untuk diselesaikan sesuai kebutuhan",
          style: "TableInstruksi",
        },
      ];

      let leftDPenerima = [];
      let rightDPenerima = [];
      this.state.detail.receiverPersonal.map(function (element, index) {
        if ((index + 1) % 2 == 1) {
          leftDPenerima.push(element);
        }

        if ((index + 1) % 2 == 0) {
          rightDPenerima.push(element);
        }
      });
      let DPenerima = [[]];

      for (var i = 0; i < leftDPenerima.length; i++) {
        DPenerima[[i]] = [
          { text: leftDPenerima[i] ? leftDPenerima[i].text : "" },
          { text: "" },
          { text: rightDPenerima[i] ? rightDPenerima[i].text : "" },
          { text: "" },
        ];
      }

      let leftDPenerima2 = [];
      let rightDPenerima2 = [];
      this.state.detail.penerima.map(function (element, index) {
        if ((index + 1) % 2 == 1) {
          leftDPenerima2.push(element.text);
        } else {
          rightDPenerima2.push(element.text);
        }
      });
      let DPenerima2 = [[]];
      for (var i = 0; i < leftDPenerima2.length; i++) {
        DPenerima2[[i]] = [
          {
            text: leftDPenerima2[i] ? leftDPenerima2[i] : "",
            style: "TableInstruksi",
          },
          { text: "" },
          {
            text: rightDPenerima2[i] ? rightDPenerima2[i] : "",
            style: "TableInstruksi",
          },
          { text: "" },
        ];
      }

      let leftDKoordinasi = [];
      let rightDKoordinasi = [];

      this.state.detail.koordinasi.map(function (element, index) {
        if ((index + 1) % 2 == 1) {
          leftDKoordinasi.push(element.text);
        } else {
          rightDKoordinasi.push(element.text);
        }
      });
      let DKoordinasi = [[]];
      for (var i = 0; i < leftDKoordinasi.length; i++) {
        DKoordinasi[[i]] = [
          {
            text: leftDKoordinasi[i] ? leftDKoordinasi[i] : "",
            style: "TableInstruksi",
          },
          { text: "" },
          {
            text: rightDKoordinasi[i] ? rightDKoordinasi[i] : "",
            style: "TableInstruksi",
          },
          { text: "" },
        ];
      }

      var documentDefinition = {};
      const user = JSON.parse(localStorage.getItem("user"));

      if (user.unit_id == 360) {
        documentDefinition = {
          header: [
            {
              columns: [
                {
                  text: ["Kementerian Luar Negeri \n", "Republik Indonesia"],
                  style: "tableHeader1",
                  margin: [50, 20, 0, 5],
                },
                {},
              ],
            },
          ],

          content: [
            {
              marginLeft: 50,
              marginRight: 50,
              table: {
                headerRows: 1,
                widths: [100, "*", 100, "*"],
                body: [
                  [
                    {
                      text: "LEMBAR PENGANTAR DISPOSISI",
                      style: "tableHeader",
                      colSpan: 4,
                      margin: [0, 0, 0, 5],
                    },
                  ],
                ],
              },
              layout: "headerLineOnly",
            },
            {
              // marginLeft: 50,
              marginRight: 50,
              table: {
                headerRows: 1,
                widths: [100, "*", 100, "*"],
                body: [
                  [
                    {
                      text: "Atas Petunjuk Kepala Biro Dukungan Strategis Pimpinan, bersama ini dengan hormat kami sampaikan surat/berita/nota:",
                      style: "tableHeader222",
                      colSpan: 4,
                      margin: [0, 0, 0, 5],
                    },
                  ],
                ],
              },
              layout: "headerLineOnly",
            },
            {
              style: "tableExample",
              color: "#444",
              table: {
                widths: ["*", "*", 225],
                headerRows: 1,
                body: [
                  [
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      colSpan: 2,
                      columns: [
                        [
                          {
                            text: "Nomor      " + ": " + this.surat.nomor_surat,
                            style: "header22",
                          },
                          {
                            text:
                              "Tanggal    " + ": " + this.surat.tanggal_surat,
                            style: "header22",
                          },
                          {
                            text:
                              "Asal           " +
                              ": " +
                              this.surat.sender.unit.name,
                            style: "header22",
                          },
                          {
                            text: "Perihal      " + ": " + this.surat.subject,
                            style: "header22",
                          },
                        ],
                      ],
                    },
                    {
                      text: "",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "   ",
                            style: "header2",
                          },
                          {
                            text: "   ",
                            style: "header2",
                          },
                          {
                            text: "   ",
                            style: "header2",
                          },
                          {
                            text: "   ",
                            style: "header2",
                          },
                          {
                            text: "   ",
                            style: "header2",
                          },
                          {
                            text: "   ",
                            style: "header2",
                          },
                          {
                            text: "   ",
                            style: "header2",
                          },
                          {
                            text: "   ",
                            style: "header2",
                          },
                        ],
                      ],
                    },
                  ],
                  [
                    {
                      table: {
                        headerRows: 0,
                        widths: [
                          10,
                          "*",
                          10,
                          20,
                          10,
                          "*",
                          10,
                          20,
                          10,
                          "*",
                          10,
                          20,
                        ],
                        body: DMPenerima,
                      },
                      colSpan: 3,
                      margin: [0, 0],
                      style: "header12",
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: "INSTRUKSI",
                      style: "header12",
                      alignment: "center",
                    },
                    {
                      text: "DISPOSISI MENTERI LUAR NEGERI",
                      style: "header12",
                      alignment: "center",
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      style: "tableExample21",
                      table: {
                        headerRows: 0,
                        widths: [10, "*"],
                        body: [
                          ["  ", "Untuk diselesaikan sesuai ketentuan"],
                          ["  ", "Siapkan Jawaban"],
                          ["  ", "Wakili Saya"],
                          ["  ", "Koordinasikan"],
                          ["  ", "Bahas dengan Saya"],
                          ["  ", "Tanggapan"],
                          ["  ", "Untuk Perhatian"],
                          ["  ", "Setuju"],
                          ["  ", "Regret"],
                          ["  ", "File"],
                        ],
                      },
                      margin: [5, 15],
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: "Meneruskan Disposisi Menlu RI Tanggal ....",
                      style: "header233",
                      alignment: "left",
                      colSpan: 3,
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: "Tgl Terima",
                      style: "header233",
                      alignment: "center",
                    },
                    {
                      text: "Tgl Selesai",
                      style: "header233",
                      alignment: "center",
                    },
                    {
                      text: "Jakarta, ....",
                      style: "header233",
                      alignment: "left",
                    },
                  ],
                  [
                    {
                      text: "25 September 2019",
                      style: "header233",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "header233",
                      alignment: "center",
                    },
                    {
                      text: "",
                      style: "header233",
                      alignment: "center",
                    },
                  ],
                ],
              },
            },
          ],

          styles: {
            tableHeader: {
              bold: true,
              fontSize: 11,
              color: "black",
              alignment: "center",
            },
            tableHeader222: {
              bold: true,
              fontSize: 8,
              color: "black",
              alignment: "left",
            },
            tableHeader1: {
              bold: true,
              fontSize: 10,
              color: "black",
              alignment: "left",
            },
            headerTitle: {
              height: 10,
              fontSize: 12,
              color: "black",
              bold: true,
            },
            TableInstruksi: {
              fontSize: 8,
              color: "black",
            },
            TableInstruksi2: {
              fontSize: 4,
              color: "black",
            },
            headerTitleCenter: {
              height: 10,
              fontSize: 12,
              color: "black",
              bold: true,
              alignment: "center",
            },
            header: {
              bold: true,
              fontSize: 26,
              color: "black",
              alignment: "center",
            },
            header233: {
              fontSize: 8,
              color: "black",
            },
            header22: {
              fontSize: 8,
              color: "black",
              fontStyle: "arial",
            },
            header11: {
              fontSize: 6,
              color: "black",
              fontStyle: "arial",
            },
            header10: {
              fontSize: 6,
              color: "black",
              alignment: "left",
              fontStyle: "arial",
            },
            tableExample21: {
              fontSize: 6,
              color: "black",
              alignment: "left",
              fontStyle: "arial",
            },
            header12: {
              fontSize: 8,
              color: "black",
              alignment: "left",
              fontStyle: "arial",
            },
            header12i: {
              fontSize: 6,
              color: "black",
              alignment: "left",
              fontStyle: "arial",
            },
            header12i1: {
              fontSize: 6,
              bold: true,
              color: "black",
              alignment: "left",
              fontStyle: "arial",
            },
            tableExample: {
              margin: [0, 5, 0, 15],
            },
            header1: {
              bold: true,
              fontSize: 8,
              color: "black",
              alignment: "center",
            },
            header2: {
              bold: true,
              fontSize: 10,
              color: "black",
              alignment: "left",
            },
            header4: {
              bold: true,
              fontSize: 8,
              color: "black",
              alignment: "left",
            },
            tableTitleDetail: {
              bold: true,
              fontSize: 12,
              color: "black",
              alignment: "center",
            },
          },
          pageMargins: [40, 60, 40, 60],
          pageSize: "A4",
        };
      } else if (user.unit_id == 361) {
        documentDefinition = {
          header: [
            {
              columns: [
                {
                  text: ["Kementerian Luar Negeri \n", "Republik Indonesia"],
                  style: "tableHeader1",
                  margin: [50, 20, 0, 15],
                },
                {},
              ],
            },
          ],

          content: [
            {
              marginLeft: 50,
              marginRight: 50,
              table: {
                headerRows: 1,
                widths: [100, "*", 100, "*"],
                body: [
                  [
                    {
                      text: "LEMBAR PENGANTAR DISPOSISI",
                      style: "tableHeader",
                      colSpan: 4,
                      margin: [20, 10, 0, 15],
                    },
                  ],
                ],
              },
              layout: "headerLineOnly",
            },
            {
              style: "tableExample",
              color: "#444",
              table: {
                widths: ["*", "*", "*"],
                headerRows: 1,
                body: [
                  [
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      colSpan: 2,
                      columns: [
                        [
                          {
                            text: "Nomor",
                            style: "header12",
                          },
                          {
                            text: this.state.detail.agenda_number,
                            style: "header2",
                          },
                          {
                            text: "Tanggal",
                            style: "header12",
                          },
                          {
                            text: this.tanggaldokumen,
                            style: "header2",
                          },
                          {
                            text: "Asal",
                            style: "header12",
                          },
                          {
                            text: this.state.detail.surat.sender.unit.name,
                            style: "header2",
                          },
                        ],
                      ],
                    },
                    {},
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "Perihal : ",
                            style: "header2",
                          },
                          {
                            text: "   ",
                            style: "header2",
                          },
                          {
                            text: "   ",
                            style: "header2",
                          },
                          {
                            text: "   ",
                            style: "header2",
                          },
                          {
                            text: "   ",
                            style: "header2",
                          },
                          {
                            text: "   ",
                            style: "header2",
                          },
                        ],
                      ],
                    },
                  ],
                  [
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      colSpan: 2,
                      columns: [
                        [
                          {
                            text: "   ",
                            style: "header2",
                          },
                          {
                            text: "   ",
                            style: "header2",
                          },
                          {
                            text: "   ",
                            style: "header2",
                          },
                          {
                            text: "   ",
                            style: "header2",
                          },
                          {
                            text: "   ",
                            style: "header2",
                          },
                          {
                            text: "   ",
                            style: "header2",
                          },
                          {
                            text: "   ",
                            style: "header2",
                          },
                          {
                            text: "   ",
                            style: "header2",
                          },
                        ],
                      ],
                    },
                    {},
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "   ",
                            style: "header2",
                          },
                          {
                            text: "   ",
                            style: "header2",
                          },
                          {
                            text: "   ",
                            style: "header2",
                          },
                          {
                            text: "   ",
                            style: "header2",
                          },
                          {
                            text: "   ",
                            style: "header2",
                          },
                          {
                            text: "   ",
                            style: "header2",
                          },
                          {
                            text: "   ",
                            style: "header2",
                          },
                          {
                            text: "   ",
                            style: "header2",
                          },
                        ],
                      ],
                    },
                  ],
                  [
                    {
                      table: {
                        headerRows: 0,
                        widths: ["*", 15, "*", 15],
                        body: DPenerima,
                        style: "header233",
                      },
                      margin: [5, 15],
                      colSpan: 2,
                    },
                    {},
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                      columns: [
                        [
                          {
                            text: "DISPOSISI",
                            style: "header2",
                          },
                          {
                            text: "Wakil Menteri Luar Negeri",
                            style: "header2",
                          },
                          {
                            text: "   ",
                          },
                          {
                            text: "   ",
                          },
                        ],
                      ],
                    },
                  ],
                ],
              },
            },
          ],

          styles: {},
          pageMargins: [40, 60, 40, 60],
          pageSize: "A4",
        };
      } else if (
        user.eselon == "Eselon II" ||
        user.eselon == "Eselon III" ||
        user.eselon == "Eselon IV" ||
        user.eselon == null
      ) {
        documentDefinition = {
          header: [
            {
              columns: [
                {
                  text: ["Kementerian Luar Negeri \n", "Republik Indonesia"],
                  style: "tableHeader1",
                  margin: [50, 20, 0, 15],
                },
                {},
              ],
            },
          ],
          content: [
            {
              marginLeft: 50,
              marginRight: 50,
              table: {
                headerRows: 1,
                widths: [100, "*", 100, "*"],
                body: [
                  [
                    {
                      text: this.state.detail.surat.sifat,
                      style: "tableHeader",
                      colSpan: 4,
                      margin: [300, 5, 0, 5],
                    },
                  ],
                ],
              },
              layout: "headerLineOnly",
            },
            {
              marginLeft: 50,
              marginRight: 50,
              table: {
                headerRows: 1,
                widths: [100, "*", 100, "*"],
                body: [
                  [
                    {
                      text: this.state.detail.surat.penerima_surat,
                      style: "tableHeader",
                      colSpan: 4,
                      margin: [20, 10, 0, 15],
                    },
                  ],
                ],
              },
              layout: "headerLineOnly",
            },
            {
              style: "tableExample",
              color: "#444",
              table: {
                widths: ["*", 100, 100],
                headerRows: 1,
                body: [
                  [
                    {
                      text: "",
                      // style: 'header2',
                      alignment: "left",
                      rowSpan: 7,
                      columns: [
                        [
                          {
                            text: "Perihal",
                            bold: true,
                            // style: "header2",
                          },
                          {
                            text: this.state.detail.surat.subject,
                            style: "header3",
                          },
                        ],
                      ],
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      colSpan: 2,
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "Kode",
                            style: "header2",
                          },
                          {
                            // text: "Indeks",
                            text: this.state.detail.surat.nomor_surat,
                            style: "header2",
                            colSpan: 2,
                          },
                          {
                            text: "Indeks",
                            style: "header2",
                          },
                          {
                            text: "   ",
                            style: "header2",
                            colSpan: 2,
                          },
                        ],
                      ],
                    },
                    {},
                  ],
                  [
                    {},
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {},
                    {
                      colSpan: 2,
                      columns: [
                        [
                          {
                            text: "Asal",
                            style: "TableInstruksi",
                          },
                          {
                            text: this.state.detail.surat.sender.unit.name,
                            // text: ''
                          },
                        ],
                      ],
                    },
                    {},
                  ],
                  [
                    {},
                    {
                      colSpan: 2,
                      columns: [
                        [
                          {
                            text: "No Agenda ",
                            style: "TableInstruksi",
                            rowSpan: 3,
                            colSpan: 2,
                          },
                          {
                            text: this.state.detail.surat.agenda_number,
                            // text: '',
                            style: "TableInstruksi",
                            rowSpan: 3,
                            colSpan: 2,
                          },
                        ],
                      ],
                    },
                    {},
                  ],
                  [
                    {},
                    {
                      colSpan: 2,
                      columns: [
                        [
                          {
                            text: "Tanggal ",
                            style: "TableInstruksi",
                            alignment: "center",
                            rowSpan: 3,
                            colSpan: 2,
                          },
                          {
                            text: this.tanggaldokumen,
                            // text: '',
                            style: "TableInstruksi",
                            alignment: "center",
                            rowSpan: 3,
                            colSpan: 2,
                          },
                        ],
                      ],
                    },
                    {},
                  ],
                  [
                    {},
                    {
                      columns: [
                        [
                          {
                            text: "Tgl.Terima ",
                            style: "TableInstruksi",
                            alignment: "center",
                            rowSpan: 3,
                            colSpan: 2,
                          },
                          {
                            text: this.tanggaldokumenditerima,
                            // text: '',
                            style: "TableInstruksi",
                            alignment: "center",
                            rowSpan: 3,
                            colSpan: 2,
                          },
                        ],
                      ],
                    },
                    {
                      columns: [
                        [
                          {
                            text: "Tgl.Selesai",
                            style: "TableInstruksi",
                            alignment: "center",
                            rowSpan: 3,
                            colSpan: 2,
                          },
                          {
                            text: this.tanggaldokumenselesai,
                            // text: '',
                            style: "TableInstruksi",
                            alignment: "center",
                            rowSpan: 3,
                            colSpan: 2,
                          },
                        ],
                      ],
                    },
                  ],
                  [{}, {}, {}],
                  [
                    {
                      text: "DISPOSISI KEPADA",
                      style: "TableInstruksi",
                    },
                    {
                      colSpan: 2,
                      rowSpan: 4,
                      text: "INFORMATION / CATATAN",
                    },
                    "",
                  ],
                  [
                    {
                      table: {
                        headerRows: 0,
                        widths: ["*", 30, "*", 30],
                        body: DPenerima2,
                        //     body: ''
                      },
                      margin: [5, 15],
                    },
                    {
                      columns: [this.state.detail.surat.note],
                    },
                    {},
                  ],
                  [
                    {
                      text: "ISI INSTRUKSI",
                      style: "TableInstruksi",
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      table: {
                        headerRows: 0,
                        widths: ["*", 30, "*", 30],
                        body: Instruction,
                      },
                      margin: [5, 15],
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: "Paraf",
                    },
                    {
                      text: "",
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: "Tanggal",
                    },
                    {
                      text: "",
                      colSpan: 2,
                    },
                    {},
                  ],
                ],
              },
            },
          ],

          styles: {
            tableHeader: {
              bold: true,
              fontSize: 11,
              color: "black",
              alignment: "center",
            },
            tableHeader1: {
              bold: true,
              fontSize: 10,
              color: "black",
              alignment: "left",
            },
            TableInstruksi: {
              fontSize: 8,
              color: "black",
            },
            TableInstruksi2: {
              fontSize: 4,
              color: "black",
            },
            headerTitle: {
              height: 10,
              fontSize: 12,
              color: "black",
              bold: true,
            },
            headerTitleCenter: {
              height: 10,
              fontSize: 12,
              color: "black",
              bold: true,
              alignment: "center",
            },
            header: {
              bold: true,
              fontSize: 26,
              color: "black",
              alignment: "center",
            },
            tableExample: {
              margin: [0, 5, 0, 15],
            },
            header233: {
              fontSize: 8,
              color: "black",
            },
            header1: {
              bold: true,
              fontSize: 8,
              color: "black",
              alignment: "center",
            },
            header2: {
              bold: true,
              fontSize: 10,
              color: "black",
              alignment: "left",
            },
            header4: {
              bold: true,
              fontSize: 8,
              color: "black",
              alignment: "left",
            },
            tableTitleDetail: {
              bold: true,
              fontSize: 12,
              color: "black",
              alignment: "center",
            },
          },
          pageMargins: [40, 60, 40, 60],
          pageSize: "A4",
        };
      } else if (user.eselon == "Eselon I") {
        documentDefinition = {
          header: [
            {
              columns: [
                {
                  text: ["Kementerian Luar Negeri \n", "Republik Indonesia"],
                  style: "tableHeader1",
                  margin: [50, 10, 0, 5],
                },
                {},
              ],
            },
          ],
          content: [
            {
              marginLeft: 50,
              marginRight: 50,
              table: {
                headerRows: 1,
                widths: [100, "*", 100, "*"],
                body: [
                  [
                    {
                      text: this.state.detail.surat.sifat,
                      style: "tableHeader",
                      colSpan: 4,
                      margin: [0, -5, -10, -5],
                    },
                  ],
                ],
              },
              layout: "headerLineOnly",
            },
            {
              marginLeft: 50,
              marginRight: 50,
              table: {
                headerRows: 1,
                widths: [100, "*", 100, "*"],
                body: [
                  [
                    {
                      text: this.state.detail.surat.penerima_surat,
                      style: "tableHeader",
                      colSpan: 4,
                      margin: [20, 10, 0, 15],
                    },
                  ],
                ],
              },
              layout: "headerLineOnly",
            },
            {
              style: "tableExample",
              color: "#444",
              table: {
                widths: ["*", 100, 100],
                headerRows: 1,
                body: [
                  [
                    {
                      text: "",
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "Perihal",
                            bold: true,
                          },
                          {
                            text: this.state.detail.surat.subject,
                            style: "header3",
                          },
                        ],
                      ],
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      colSpan: 2,
                      alignment: "left",
                      columns: [
                        [
                          {
                            text: "Kode",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.surat.nomor_surat,
                            style: "header2",
                            colSpan: 2,
                          },
                        ],
                        [
                          {
                            text: "Indeks",
                            style: "header2",
                          },
                          {
                            text: "",
                            style: "header2",
                            colSpan: 2,
                          },
                        ],
                      ],
                    },
                    {},
                  ],
                  [
                    {
                      text: "KOORDINASI",
                      style: "TableInstruksi3",
                      alignment: "left",
                    },
                    {
                      text: "",
                      style: "tableHeader",
                      alignment: "center",
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      // rowSpan: 4,
                      // text: ''
                      table: {
                        headerRows: 0,
                        style: "TableInstruksi2",
                        widths: ["*", 30, "*", 30],
                        body: DKoordinasi,
                      },
                      margin: [5, 15],
                    },
                    {
                      colSpan: 2,
                      columns: [
                        [
                          {
                            text: "Asal",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.surat.sender.unit.name,
                            style: "TableInstruksi",
                            // text: ''
                          },
                          {
                            text: "  ",
                          },
                          {
                            text: "No Agenda",
                            style: "header2",
                          },
                          {
                            text: this.state.detail.agenda_number,
                            style: "TableInstruksi",
                          },
                          {
                            text: "   ",
                          },
                          {
                            text: "Tanggal",
                            style: "header2",
                          },
                          {
                            text: this.tanggaldokumen,
                            style: "TableInstruksi",
                          },
                          {
                            text: "   ",
                          },
                          {
                            text: "Tgl. Terima",
                            style: "header2",
                          },
                          {
                            text: this.tanggaldokumenditerima,
                            style: "TableInstruksi",
                          },
                          {
                            text: "   ",
                          },
                          {
                            text: "Tgl. Selesai",
                            style: "header2",
                          },
                          {
                            text: this.tanggaldokumenselesai,
                            style: "TableInstruksi",
                          },
                        ],
                      ],
                    },
                    {},
                  ],
                  [
                    {
                      text: "DISPOSISI KEPADA",
                      style: "TableInstruksi3",
                    },
                    {
                      colSpan: 2,
                      rowSpan: 4,
                      text: "INFORMATION / CATATAN",
                    },
                    "",
                  ],
                  [
                    {
                      table: {
                        headerRows: 0,
                        widths: ["*", 30, "*", 30],
                        body: DPenerima2,
                      },
                      margin: [5, 15],
                    },
                    {
                      columns: [this.state.detail.surat.note],
                    },
                    {},
                  ],
                  [
                    {
                      text: "ISI INSTRUKSI",
                      style: "TableInstruksi3",
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      table: {
                        headerRows: 0,
                        widths: ["*", 30, "*", 30],
                        body: Instruction,
                      },
                      margin: [5, 15],
                    },
                    {},
                    {},
                  ],
                  [
                    {
                      text: "Paraf",
                    },
                    {
                      text: "",
                      colSpan: 2,
                    },
                    {},
                  ],
                  [
                    {
                      text: "Tanggal",
                    },
                    {
                      text: "",
                      colSpan: 2,
                    },
                    {},
                  ],
                ],
              },
            },
          ],
          styles: {
            tableHeader: {
              bold: true,
              fontSize: 11,
              color: "black",
              alignment: "center",
            },
            tableHeader1: {
              bold: true,
              fontSize: 10,
              color: "black",
              alignment: "left",
            },
            headerTitle: {
              height: 10,
              fontSize: 12,
              color: "black",
              bold: true,
            },
            headerTitleCenter: {
              height: 10,
              fontSize: 12,
              color: "black",
              bold: true,
              alignment: "center",
            },
            TableInstruksi: {
              fontSize: 8,
              color: "black",
            },
            TableInstruksi3: {
              bold: true,
              fontSize: 8,
              color: "black",
            },
            TableInstruksi2: {
              fontSize: 4,
              color: "black",
            },
            header: {
              bold: true,
              fontSize: 26,
              color: "black",
              alignment: "center",
            },
            tableExample: {
              margin: [0, 5, 0, 15],
            },
            header1: {
              bold: true,
              fontSize: 8,
              color: "black",
              alignment: "center",
            },
            header2: {
              bold: true,
              fontSize: 10,
              color: "black",
              alignment: "left",
            },
            header4: {
              bold: true,
              fontSize: 8,
              color: "black",
              alignment: "left",
            },
            tableTitleDetail: {
              bold: true,
              fontSize: 12,
              color: "black",
              alignment: "center",
            },
          },
          pageMargins: [40, 60, 40, 60],
          pageSize: "A4",
        };
      }
      pdfMake.createPdf(documentDefinition).print();
    },
    addCheck(n) {
      console.log(n);
    },
    goTeruskan() {
      // localStorage.setItem("disposition", JSON.stringify(this.state.detail));
      this.$store.dispatch("dispositionIn/onTeruskan", this.$route.params);
    },
    goBack() {
      this.$store.dispatch("dispositionIn/onCancel");
    },
    prevDoc(string) {
      let routeData = this.$router.resolve({
        name: "preview.index",
        params: {
          document_type: "document_in",
          id: this.$route.params.id,
          file: string,
        },
      });
      window.open(routeData.href, "_blank");
    },
    prev(string) {
      let routeData = this.$router.resolve({
        name: "preview.index",
        params: {
          document_type: "disposition_in",
          id: this.$route.params.id,
          file: string,
        },
      });
      window.open(routeData.href, "_blank");
    },
    downloadDocument(filepath, filename) {
      axios
        .post("document_in/assets/single", { filepath: filepath })
        .then((res) => {
          const linkSource = res.data;
          const downloadLink = document.createElement("a");
          const fileName = filename;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );
          // downloadLink.click();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    downloadForce(url) {
      const downloadLink = document.createElement("a");

      downloadLink.href = url;
      downloadLink.target = "_blank";
      downloadLink.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    },
    downloadDisposition(filepath, filename) {
      axios
        .post("disposition_in/assets/single", { filepath: filepath })
        .then((res) => {
          const linkSource = res.data;
          const downloadLink = document.createElement("a");
          const fileName = filename;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );
          // downloadLink.click();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onDownloadLembarDispo() {
      var context = this;
      context.printDispoLoading = true;
      const url = context.urlLembarDispo;

      axios
        .get(url, {
          responseType: "blob",
        })
        .then((res) => {
          // https://stackoverflow.com/a/51514660
          let url = window.URL.createObjectURL(res.data);
          let link = document.createElement("a");
          link.href = url;
          link.target = "_blank";
          link.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );
          setTimeout(() => window.URL.revokeObjectURL(url), 100);
          link.remove();

          context.printDispoLoading = false;
        })
        .catch(function (error) {
          // https://github.com/axios/axios#handling-errors

          context.printDispoLoading = false;

          let message = "";
          console.log(error.response);
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);

            if (error.response.data.message) {
              message = error.response.data.message;
            } else {
              // TODO: handle expected HTTP status codes with a proper message,
              // otherwise return a generic message.
              message =
                "Terjadi kesalahan, silakan hubungi pengelola aplikasi.";
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);

            message =
              "Terjadi kesalahan di server, silakan coba lagi nanti atau hubungi pengelola aplikasi.";
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message || error);

            message =
              "Terjadi kesalahan, silakan cek koneksi internet Anda atau hubungi pengelola aplikasi.";
          }

          if (message && message.length) {
            const msg = {
              title: "Galat",
              text: message,
              type: "error",
            };
            context.$store.commit("addNotification", msg);
          }
        });
    },
    goJawaban() {
      this.$store.dispatch("dispositionIn/onJawaban", this.$route.params);
    },
    goJawabDispo() {
      this.$store.dispatch("dispositionIn/onJawabDispo", this.$route.params);
    },
    newTab(file) {
      window.open(file, file);
    },
  },
};
</script>
<style>
.receiver {
  list-style: none;
  margin-left: -20px;
}
.fa-none {
  width: 14px;
  height: 14px;
}
</style>

